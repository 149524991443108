<template>
  <!-- 目标人群菜单 -->
  <div class="filterShow" @mouseover="onmouseover" @mouseleave="onmouseout">
    <!--显示的人群-->
    <div class="crowedShow" :class="{ filterHover: isShow }">
      <svg-icon
        class-name="iconuser"
        :style="{ fontSize: '26px', color: isShow ? '' : '#51A6F0' }"
      />
      <span style="margin: 0 10px">{{ crowd.name }}</span>
      <i class="el-icon-arrow-down" style="margin-left: 5px"></i>
    </div>
    <!--人群列表-->
    <div class="crowedItems" v-show="isShow">
      <crowed-item />
      <!--管理人群按钮-->
      <!-- <manage-com /> -->
      <manage-com />
    </div>
  </div>
</template>

<script>
import crowedItem from "../crowedItem/crowedItem";
import manageCom from "../manageBtn/manageBtn";

import { mapGetters } from "vuex";

export default {
  name: "crowedCom",
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    ...mapGetters(["dashBoardCrowd", "role"]),
    crowd() {
      return this.dashBoardCrowd;
      // return this.$route.name === "DashBoard"
      //   ? this.targetCrowd
      //   : this.colTargetCrowd;
    },
  },
  methods: {
    onmouseover: function () {
      this.isShow = true;
    },
    onmouseout: function () {
      this.isShow = false;
    },
  },
  components: {
    crowedItem,
    manageCom,
  },
};
</script>

<style scoped lang="scss">
.filterShow {
  font-size: 14px;
  position: relative;

  .crowedShow {
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .filterHover {
    background-color: $color-highlight-background;
    color: #ffffff;
  }

  .crowedItems {
    position: absolute;
    // top: 40px;
    left: 0;
    border: 1px solid $color-border;
    overflow: hidden;
    background-color: #fff;
    z-index: 3000;
  }
}
</style>
