<template>
  <el-table
    class="myTable"
    :data="kolListData"
    style="width: 100%"
    @select="selectKol"
    @select-all="selectAllKol"
    :header-cell-style="headerStyle"
    :cell-style="cellStyle"
    :expand-row-keys="expandFunc"
    row-key="mKolid"
  >
    <el-table-column type="expand">
      <template #default="{ row }">
        <div
          class="expandWrapper"
          style="background-color: rgb(240, 245, 255); border-radius: 5px"
        >
          <div class="average" style="margin-bottom: 10px">
            <el-table
              :data="buildAverageTableData(row)"
              style="width: 100%"
              :cell-style="cellStyle"
              border
            >
              <el-table-column
                class="haha"
                align="center"
                prop="title"
                label="集团合作平均表现"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="coopExposureAvg"
                label="文章阅读UV"
                width="180"
              >
                <template #default="{ row }">
                  {{
                    row.coopExposureAvg === null
                      ? "--"
                      : sliceDecimal(row.coopExposureAvg)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="coopEngagementAvg"
                label="文章互动"
                width="180"
              >
                {{
                  row.coopEngagementAvg === null
                    ? "--"
                    : sliceDecimal(row.coopEngagementAvg)
                }}
              </el-table-column>
              <el-table-column
                align="center"
                prop="coopPriceAvg"
                label="合作均价"
              >
                {{
                  row.coopPriceAvg === null
                    ? "--"
                    : sliceDecimal(row.coopPriceAvg / 100)
                }}
              </el-table-column>
              <el-table-column align="center" prop="coopCpuvAvg" label="CPUV">
                {{
                  row.coopCpuvAvg === null
                    ? "--"
                    : sliceDecimal(row.coopCpuvAvg)
                }}
              </el-table-column>
              <el-table-column align="center" prop="coopCpeAvg" label="CPE">
                {{
                  row.coopCpeAvg === null ? "--" : sliceDecimal(row.coopCpeAvg)
                }}
              </el-table-column>
            </el-table>
          </div>
          <div class="info">
            <el-table
              :data="buildInfoTableData(row)"
              style="width: 100%"
              :cell-style="cellStyle"
              border
            >
              <el-table-column
                align="center"
                prop="titleName"
                label="最近一次合作表现"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="brand"
                label="合作品牌"
                width="160"
              >
                <template #default="{ row }">
                  <span v-if="row.brand === undefined">--</span>
                  <p v-else :key="item" v-for="item in row.brand">
                    {{ item === "" || item === undefined ? "--" : item }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="coopCount"
                label="合作次数"
                width="100"
              >
                <template #default="{ row }">
                  {{
                    row.coopCount === null || row.coopCount === undefined
                      ? "--"
                      : sliceDecimal(row.coopCount)
                  }}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="coopPriceAvg" label="文章">
                <template #default="{ row }">
                  <template
                    v-if="row.title === undefined || row.title === null"
                  >
                    --
                  </template>
                  <template v-else>
                    <a
                      class="aClass"
                      style="font-size: 12px"
                      :href="row?.link"
                      target="_blank"
                      >{{ row?.title }}</a
                    >
                  </template>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="postDate" label="日期">
                <template #default="{ row }">
                  {{
                    row.postDate === null || row.postDate === undefined
                      ? "--"
                      : row.postDate
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="exposure"
                label="文章阅读UV"
              >
                <template #default="{ row }">
                  {{
                    row.exposure === null || row.exposure === undefined
                      ? "--"
                      : sliceDecimal(row.exposure)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="engagement"
                label="文章互动"
              >
                <template #default="{ row }">
                  {{
                    row.engagement === null || row.engagement === undefined
                      ? "--"
                      : sliceDecimal(row.engagement)
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column type="selection" width="30" />
    <el-table-column
      prop="kolName"
      label="KOL名称"
      align="left"
      :show-overflow-tooltip="true"
      width="100"
    >
      <template #default="{ row }">
        <div style="display: flex; align-items: center">
          <img
            style="
              border: 1px solid #fff;
              border-radius: 50%;
              width: 28px;
              height: 28px;
            "
            :src="row.logoUrl"
            alt=""
          />
          <el-tooltip
            v-if="row.activeViewerNum === null"
            effect="dark"
            placement="top"
          >
            <template #content> KOL期间内未发布内容 </template>
            <svg-icon class-name="question" class="questionClass"></svg-icon>
          </el-tooltip>

          <span
            style="margin-left: 6px"
            class="ellipsis"
            @click="nameClick(row)"
          >
            {{ row.kolName }}
          </span>
        </div>
      </template>
    </el-table-column>
    <!--排序列-->
    <el-table-column
      v-for="(item, index) in colData"
      :key="index"
      :prop="item.prop"
      :label="item.label"
      :width="setWidthByIndex(item)"
      align="center"
      :label-class-name="item.isRank ? 'colActClass' : ''"
      :class-name="item.isRank ? 'colActClass' : ''"
    >
      <!--表头-->
      <template #header>
        <div
          v-if="item.canRank"
          class="clickClass"
          :class="item.isRank ? 'colActClass' : ''"
          @click="sortClick(item.prop, item.label)"
        >
          <div v-if="item.label === '近4周触达UV'">
            <el-tooltip
              placement="top"
              :content="item.info"
              popper-class="popper"
            >
              <div style="padding: 0">
                <p style="line-height: 23px">近4周</p>
                <p style="line-height: 23px">触达UV</p>
              </div>
            </el-tooltip>
          </div>
          <div v-else-if="item.label === '单条阅读UV'">
            <el-tooltip
              placement="top"
              :content="item.info"
              popper-class="popper"
            >
              <div style="padding: 0">
                <p style="line-height: 23px">单条</p>
                <p style="line-height: 23px">阅读UV</p>
              </div>
            </el-tooltip>
          </div>
          <div v-else-if="item.label === '单条互动'">
            <el-tooltip
              placement="top"
              :content="item.info"
              popper-class="popper"
            >
              <div style="padding: 0">
                <p style="line-height: 23px">单条互动</p>
              </div>
            </el-tooltip>
          </div>
          <el-tooltip
            v-else-if="item.info !== null"
            placement="top"
            :content="item.info"
            popper-class="popper"
          >
            <span>{{ item.label }}</span>
          </el-tooltip>
          <span v-else>{{ item.label }}</span>
        </div>
        <div v-else-if="item.label === '级别'">
          <el-tooltip placement="top" popper-class="popper">
            <template #content>
              Tier1(平台top5%)<br />
              Tier2(平台5~20%)<br />
              Tier3(平台20~50%)<br />
              KOC(平台50~100%)<br />
            </template>
            <p style="line-height: 22px">级别</p>
          </el-tooltip>
        </div>
        <div v-else-if="item.label === '竞品合作次数'">
          <el-tooltip
            placement="top"
            :content="item.info"
            popper-class="popper"
          >
            <div style="padding: 0">
              <p style="line-height: 23px">竞品</p>
              <p style="line-height: 23px">合作次数</p>
            </div>
          </el-tooltip>
        </div>
        <span v-else>
          {{ item.label }}
        </span>
      </template>
      <!--表格-->
      <template #default="{ row }">
        {{ row[item.prop] === null ? "--" : sliceDecimal(row[item.prop]) }}
      </template>
    </el-table-column>
    <el-table-column align="center" label="竞品名称" prop="competitionName">
      <template #default="{ row }">
        <p v-if="row.competitionName === null">--</p>
        <template v-else>
          <template v-if="row.competitionName.split(',').length < 3">
            <p
              :key="index"
              v-for="(item, index) in row.competitionName.split(',')"
            >
              {{ item }}
            </p>
          </template>
          <template v-else>
            <p
              :key="index"
              v-for="(item, index) in row.competitionName
                .split(',')
                .slice(0, 3)"
            >
              {{ item }}
            </p>
            <p>...</p>
          </template>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      expandData: [
        {
          date: "2016-05-03",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],
      colData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "类型",
          order_id: 3,
          prop: "kolCategory",
          round: null,
          type: "String",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: `Tier1(平台top5%)
                 Tier2(平台5~20%)
                 Tier3(平台20~50%)
                 KOC(平台50~100%)
          `,
          isRank: 0,
          label: "级别",
          order_id: 30,
          prop: "tier",
          round: null,
          type: "int",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条文章/视频去水阅读UV数中位数",
          isRank: 0,
          label: "单条阅读UV",
          order_id: 4,
          prop: "postRead",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "TA UV",
          isRank: 1,
          label: "TA UV",
          order_id: 5,
          prop: "taPostRead",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条文章/视频去水互动（转评赞藏等加总）中位数",
          isRank: 0,
          label: "单条互动",
          order_id: 10,
          prop: "postEngagement",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人受众中TA人群占比",
          isRank: 0,
          label: "TA浓度%",
          order_id: 11,
          prop: "taRatio",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人TA浓度除以平台TA浓度",
          isRank: 0,
          label: "TA TGI",
          order_id: 6,
          prop: "taTgi",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "单个UV触达成本",
          isRank: 0,
          label: "CPUV",
          order_id: 6,
          prop: "cpuv",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "价格 / 月单篇互动数",
          isRank: 0,
          label: "CPE",
          order_id: 7,
          prop: "cpe",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "单个TA触达成本",
          isRank: 0,
          label: "CPTA",
          order_id: 7,
          prop: "cpta",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "金额",
          isRank: 0,
          label: "金额",
          order_id: 7,
          prop: "price",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: "合作次数",
          isRank: 0,
          label: "合作次数",
          order_id: 7,
          prop: "coopCount",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: "竞品合作次数",
          isRank: 0,
          label: "竞品合作次数",
          order_id: 8,
          prop: "competitionCount",
          round: 2,
          type: "Percent",
        },
      ],
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
        overflow: "hidden",
      },
      cellStyle: {
        fontSize: "12px",
      },
      tableData: [],
      kolIdList: [],
      averageTableData: [],
      infoTableData: [],
    };
  },
  props: {
    kolListData: {
      type: Array,
      default() {
        return [];
      },
    },
    brand: {
      type: String,
      default: "",
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    expandFunc() {
      if (!this.isExpand) {
        return [];
      } else {
        return this.kolListData.map((v) => {
          return v.mKolid;
        });
      }
    },
  },
  methods: {
    buildAverageTableData(data) {
      let tempData = [];
      console.log(data?.coopPriceAvg / 100);
      tempData.push({
        title: "",
        coopExposureAvg: data?.coopExposureAvg,
        coopEngagementAvg: data?.coopEngagementAvg,
        coopPriceAvg: data?.coopPriceAvg,
        coopCpuvAvg: data?.coopCpuvAvg,
        coopCpeAvg: data?.coopCpeAvg,
      });
      return tempData;
    },
    buildInfoTableData(row) {
      console.log(row);
      let tempData = [];
      tempData.push(
        {
          titleName: "本品",
          brand: row?.latestCoopInfo?.brand.split(","),
          coopCount: row?.latestCoopInfo?.coopCount,
          engagement: row?.latestCoopInfo?.engagement,
          exposure: row?.latestCoopInfo?.exposure,
          link: row?.latestCoopInfo?.link,
          postDate: row?.latestCoopInfo?.postDate,
          title: row?.latestCoopInfo?.title.replace(/&nbsp;/g, " "),
        },
        {
          titleName: "竞品",
          brand: row?.latestCompetitionInfo?.brand.split(","),
          coopCount: row?.latestCompetitionInfo?.coopCount,
          engagement: row?.latestCompetitionInfo?.engagement,
          exposure: row?.latestCompetitionInfo?.exposure,
          link: row?.latestCompetitionInfo?.link,
          postDate: row?.latestCompetitionInfo?.postDate,
          title: row?.latestCompetitionInfo?.title.replace(/&nbsp;/g, " "),
        }
      );
      return tempData;
    },
    chekboxValueChange() {
      let tempArr = [];
      this.kolIdList.forEach((item) => {
        tempArr.push(item.mKolid);
      });
      this.$emit("selKol", tempArr, this.kolIdList);
    },
    selectKol(selection) {
      console.log(selection);
      this.kolIdList = selection;
      this.chekboxValueChange();
    },
    selectAllKol(selection) {
      console.log(selection);
      this.kolIdList = selection;
    },
    setWidthByIndex(item) {
      if (item.label === "TA TGI") {
        return 75;
      } else if (item.label === "类型") {
        return 35;
      } else if (item.label === "级别") {
        return 60;
      } else if (item.label === "周作品数量") {
        return 70;
      } else {
        return null;
      }
    },
    sortClick(prop, label) {
      this.rankCol = prop;
      this.choseColData();
      this.$emit("changeSort", prop, label);
    },
    choseColData() {
      this.handleIsRank(this.colData);
    },
    handleIsRank(colData) {
      colData.forEach((v) => {
        v.isRank = 0;
        if (v.prop === this.rankCol) {
          v.isRank = 1;
        }
      });
    },
  },
  watch: {
    kolListData(val) {
      // averageTableData: [],
      // infoTableData: [],
      // [{12,12,21,21,21}]
      val.forEach((v) => {
        v.price = v.price / 100;
        this.averageTableData.push({
          coopExposureAvg: v.coopExposureAvg,
          coopEngagementAvg: v.coopEngagementAvg,
          coopPriceAvg: v.coopPriceAvg,
          coopCpuvAvg: v.coopCpuvAvg,
          coopCpeAvg: v.coopCpeAvg,
        });
      });
      console.log(this.averageTableData);
      this.tableData = val;
      console.log(this.tableData);
    },
  },
};
</script>

<style lang="scss">
.kolTable {
  .el-table__cell.el-table__expanded-cell {
    padding: 10px 40px;
  }
  // 设置表格样式
  .average .el-table__header-wrapper {
    overflow: visible;
    thead tr :first-child {
      border-bottom: none;
      overflow: visible;
      .cell {
        position: relative;
        overflow: visible;
        top: 16px;
        z-index: 10;
      }
    }
  }
  .aClass {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    &:hover {
      color: #51a6f0;
    }
  }
}
</style>

<style lang="scss" scoped>
.expandWrapper {
  padding: 20px;
}
.average {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  .pClass {
    margin-right: 40px;
    width: 110px;
    // line-height: 40px;
    p {
      text-align: center;
    }
  }
}
.info {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.latestCompetitionInfo,
.latestCoopInfo {
  font-size: 14px;
  text-align: center;
  display: flex;

  padding: 5px;
  div {
    margin-right: 60px;
  }
}
</style>
