<template>
  <div class="legend_contain">
    <i class="legend_icon" :style="{ backgroundColor: data.color }"></i>
    <span class="ellipsis legend_name" :title="data.name">{{ data.name }}</span>
  </div>
</template>

<script>
export default {
  name: "LegendCom",
  props: {
    data: {
      type: Object,
      default() {
        return {
          name: "",
          color: "",
        };
      },
    },
  },
};
</script>

<style scoped lang="scss">
.legend_contain {
  display: flex;
  align-items: center;
  width: 100px;
  height: 24px;
  line-height: 24px;
  margin-right: 20px;

  .legend_icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    margin-right: 6px;
  }
  .legend_name {
    max-width: 80px;
  }
}
</style>
